require('./bootstrap');

$(document).ready(function(){
    var scroll = $(window).scrollTop();
    const $navbar = $('.mainnav');
    const $bottomNavbar = $('.footer');

    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('#nav-links').toggleClass("show");
        $('#mainnav').toggleClass("menu-open");
        $('#bg-overlay').toggleClass("show");
        $(".nav-dropdown").hide();
        $('.nav-logo-headline').toggleClass("text-white");
    });
    $("#bg-overlay").click(function(){
        $(this).removeClass("show");
        $('#nav-links').removeClass("show");
        $('.hamburger').removeClass("is-active");
        $('#mainnav').removeClass("menu-open");
        $(".nav-dropdown").hide();
    });

    $(".dropdown-toggle-link").click(function() {
        $(this).next(".nav-dropdown").slideToggle();
        $(this).toggleClass('toggled');
    });
});
